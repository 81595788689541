import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout title="Not found">
    <section className="section">
      <div className="container">
        <h1 className="title is-1 has-text-centered">NOT FOUND :(</h1>
        <p className="has-text-centered">
          The page you are trying to reach doesn't seem to exist.
        </p>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
